import cx from 'classnames';
import { getAvailableMarkets } from 'utils/market';
import { EXCLUDED_MARKETS } from 'constants/markets';
import MapCountryPaths from '../assets/map-country-paths.json';
import styles from './Map.module.scss';

type MapProps = {
  color?: 'light' | 'dark';
};

const COMMON_CIRCLE_PROPS = {
  fill: 'var(--circleColor)',
  stroke: 'var(--circleBorderColor)',
  fillOpacity: '0.3',
  strokeOpacity: '0.4',
};

const Map = ({ color = 'light' }: MapProps) => {
  const visibleMarkets = Object.keys(getAvailableMarkets(EXCLUDED_MARKETS));

  return (
    <div className={cx(styles.root, styles[color])}>
      <span className={styles.map}>
        <svg viewBox="0 0 918 564" fill="none" xmlns="http://www.w3.org/2000/svg">
          {Object.entries(MapCountryPaths).map(([country, path]) => {
            const filled = visibleMarkets.includes(country);

            return (
              <path
                key={country}
                id={country}
                d={path}
                className={cx(filled && styles.filled)}
                fill="var(--fillColor)"
                stroke="var(--borderColor)"
              />
            );
          })}
          <ellipse cx="505" cy="232.066" rx="140.5" ry="141" {...COMMON_CIRCLE_PROPS} />
          <circle cx="786" cy="458.566" r="104.5" {...COMMON_CIRCLE_PROPS} />
          <circle cx="191.5" cy="237.066" r="191" {...COMMON_CIRCLE_PROPS} />
        </svg>
      </span>
    </div>
  );
};

export { Map };
